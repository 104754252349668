import React from 'react';
import Container from 'react-bootstrap/Container';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import CountUp from 'react-countup';

function IntroSection() {
  return (
    <div className="site-section">
      <Container fluid="md">
        <Row>
          <Col md={6}>
            <div className="block-48" id="counter-section">
              <span className="block-48-text-1">Join more than</span>
              <div>
                <span className="block-48-counter">
                  <CountUp
                    start={0}
                    end={3000}
                    duration={3.75}
                  />
                </span>
              </div>
              <span className="block-48-text-1 d-block">Bigs and Minis across the world.</span>
            </div>
          </Col>
          <Col md={6}>
            <br />
            <h2 className="display-4 mb-3">Meet Big & Mini</h2>
            <p className="lead">
              {/* We connect generations.  */}
              A
              community working together to spread wisdom, end social isolation,
              and make the world a little better— one generation-bridging connection at a time.

            </p>
          </Col>

        </Row>
      </Container>
    </div>
  );
}

export default IntroSection;
