import React, { useState, useEffect } from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import { ParallaxProvider } from 'react-scroll-parallax';
import Link from 'next/link';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import Head from 'next/head';
import { useSelector } from 'react-redux';
import HeroSection from '@/components/homepage/HeroSection';
import HomepageNewsCard from '@/components/homepage/HomepageNewsCard';
import Footer from '@/components/common/widgets/Footer';
import Layout from '@/components/common/layouts/Layout';
import IEPopup from '@/components/common/IEPopup';
import { useUserContext } from '@/lib/context/useUserContext';
import { useRouter } from 'next/router';
import IntroSection from './homepage/IntroSection';
import BigAndMiniSection from './homepage/BigAndMiniSection';
import HomeActionSection from './homepage/HomeActionSection';
// import OurPartnersSection from './homepage/OurPartnersSection';
// import OurNewsSection from './homepage/OurNewsSection';
import OurPartnersSection from './homepage/OurPartnersSection';
import StepOneSection from './homepage/StepOneSection';
import StepTwoSection from './homepage/StepTwoSection';
// import TestimonialSection from './homepage/TestimonialSection';
import Testimonials from './homepage/testimonials';
import VideoSection from './homepage/VideoSection';
import { getNPosts } from './api/ghost';
import NewsFeatures from './homepage/newsfeatures';
// import PlayButton from './homepage/PlayButton';

function Homepage() {
  const { isValid } = useUserContext();
  const router = useRouter();

  if (isValid) {
    window.location.replace('/dashboard/match');
  } else {
    router.replace('/signup');
  }
  return null;
}
export default Homepage;
