import React from 'react';
import Container from 'react-bootstrap/Container';

import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

function HomeActionSection() {
  return (
    <div className="site-section" id="dark-gray-background" style={{ paddingTop: '0' }}>
      <Container className="centered">
        <br />
        <h1 className="heading-light">Getting started is easy</h1>
        <br />
        <h3 className="light">
          Making a real connection is as simple as clicking the button below

        </h3>
        <br />
        <a href="signup" className="hero-button" id="`dark-yellow">
          Sign Me Up
        </a>
      </Container>

    </div>
  );
}

export default HomeActionSection;
